/**
 * @description 2023中秋国庆活动
 * @returns route: /front/activities/springFestival
 */

import React, { useState, useEffect } from "react";
import { Toast } from "antd-mobile";
import { Header, RulesModal } from "components";
import Library from "library";
import Service from "../service";
import ShopRecommend from "../components/shopRemmend";
import Styles from "./styles.module.scss";
import PopupShare from "../components/popupShare";
import ShareModal from "../components/shareModal";
import Interceptor from "../components/interceptor";

const { JsBridge, Platform } = Library;

export default (props) => {
  const { clientId } = Library.Util.url.paramsToObj();

  const [activityInfo, setActivityInfo] = useState({});
  const [shareConfig, setShareConfig] = useState({});

  useEffect(() => {
    if (clientId) {
      Service.shareActivityTask({ clientId });
    }
    loadData();
  }, []);

  const loadData = async () => {
    const result = await Service.getShareConfig();
    if (result?.data) {
      setShareConfig(result.data);
    } else {
      setShareConfig({ status: 0 });
    }
  };

  // 活动规则
  const ruleClick = () => {
    console.log("rule");
    RulesModal.open({
      btnStyle: {
        background: "linear-gradient(102deg, #FF8354 0%, #FB3D28 108%)",
      },
      activityCode: Service.activityCode,
      codeType: "activityRule",
      code: Service.activityCode,
    });
  };
  // 活动分享
  const shareActyClick = () => {
    console.log("share");
    PopupShare.open({
      activityCode: Service.activityCode,
      inviteCode: activityInfo.inviteCode,
      shareConfig,
    });
  };

  // 邀新
  const shareActyClick2 = () => {
    console.log("share");
    ShareModal.open({
      activityCode: Service.activityCode,
      inviteCode: activityInfo.inviteCode,
      shareConfig,
    });
  };

  return (
    <div className={Styles.magpiePage}>
      {JsBridge.hasWebViewBridge() && <Header bgColor={"transparent"} />}
      <div className={Styles.page_container}>
        <div className={Styles.bgTop}>
          {/* 顶部按钮布局 */}
          <div className={Styles.activityBtns}>
            <div className={Styles.activityRule}>
              <button
                onClick={() => {
                  ruleClick();
                }}
              ></button>
            </div>
            {shareConfig.status === 0 ? null : JsBridge.hasWebViewBridge() ? (
              <div className={Styles.activityShare}>
                <button
                  onClick={() => {
                    shareActyClick2();
                  }}
                ></button>
              </div>
            ) : null}
          </div>
        </div>
        <div className={Styles.magpiePage_wraper}>
          <div className={Styles.b}>
            <img src={require("images/springFestival/logo.png")} alt="" />
          </div>
          {/* 商品推荐 */}
          <ShopRecommend />
          {/* 底部logo */}
          <div className={Styles.footer_logo}>
            <img
              src={require("images/springFestival/bottom-logo.png")}
              alt=""
            />
          </div>
        </div>
        {Platform.check.isMiniprogram() || Platform.check.isApp() ? null : (
          <Interceptor />
        )}
      </div>
    </div>
  );
};
