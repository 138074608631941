/**
 * @description 商品推荐模块
 */

import React, { useState, useEffect, useRef } from "react";
import { Tabs } from "antd-mobile";
import { ListView } from "components";
import { useHistory } from "react-router-dom";

import Library from "library";
import Service from "../../service";
import Styles from "./index.module.scss";
const { JsBridge } = Library;

const ShopRecommend = () => {
  const [activeKey, setActiveKey] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const currentPage = useRef(1);
  const pageSize = 20;
  const listArray = [
    {
      id: 1,
    },
    {
      id: 2,
    },
  ];

  useEffect(() => {
    currentPage.current = 1;
    loadData(true);
  }, [activeKey]);
  const loadData = async (loading = false) => {
    if (loading) {
      setLoading(true);
    }
    const res = await Service.getRecommendShopList(
      {
        pageSize,
        activityCode:
          Number(activeKey) === 0
            ? Service?.activityCode
            : Service?.activityCode2,
        pageNum: currentPage.current,
      },
      loading
    );
    if (loading) {
      setLoading(false);
    }
    if (res?.data) {
      const list = res?.data || [];
      let resList = [];
      list.forEach((element) => {
        resList = resList.concat(element?.goodsSimplifyList || []);
      });
      console.log("resList", resList);
      // const list =[]

      setDataList(resList);
      // setHasMore(list.length >= pageSize);
    }
  };

  const loadMore = async () => {
    currentPage.current += 1;
    await loadData();
  };

  return (
    <div className={Styles.shop_recomend}>
      <Tabs
        className={Styles.tabs}
        activeKey={activeKey + ""}
        onChange={(key) => setActiveKey(key)}
      >
        {listArray.map((tab, k) => {
          return (
            <Tabs.Tab
              key={k}
              title={
                <div
                  className={`${Styles.tab} ${
                    activeKey == k ? Styles.tabSelect : Styles.tabUnSelect
                  }`}
                >
                  <span className={Styles.cateName}>
                    <img
                      src={require(`images/springFestival/tab${k}.png`)}
                      alt=""
                    />
                  </span>
                </div>
              }
            >
              <div className={Styles.listouter}>
                <ListView
                  dataSource={dataList}
                  emtyDesc={loading ? "数据加载中..." : "暂无数据"}
                  renderItem={(props) => {
                    return <ShopItem item={props} />;
                  }}
                  hasMore={false}
                  onRefresh={loadData}
                  loadMore={loadMore}
                />
              </div>
            </Tabs.Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

const ShopItem = (props) => {
  const history = useHistory();
  const { item = {} } = props;
  //商品点击事件
  const onShopItemClick = (data) => {
    console.log(data);
    const skuId = data.goodsInfoId;
    if (JsBridge.hasWebViewBridge()) {
      JsBridge.push("Web", {
        uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${skuId}`,
      });
    } else {
      window.location.href =  `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${skuId}`;
    }
  };

  const originalPrice = Number(item.marketPrice);
  const pointPrice = Number(item.maxDeductPoint || 0) / 100;
  //最终价
  const actualPrice = Math.max(
    Number((originalPrice * (item.discount || 1) - pointPrice).toFixed(2)),
    0
  );

  return (
    <div className={Styles.goods_item} onClick={() => onShopItemClick(item)}>
      <div className={Styles.item_box}>
        <div className={Styles.left}>
          <div className={Styles.conent}>
            {/* <div className={Styles.tag}> {"购买返3倍积分"}</div> */}
            <img src={item.goodsImg} alt="" />
          </div>
        </div>
        <div className={Styles.right}>
          <p>{item.goodsInfoName}</p>
          <div className={Styles.priceLine}>
            <div>
              <div>
                ¥<span>{originalPrice}</span>
              </div>
              <div>原价</div>
            </div>
            <div>
              <div>
                ¥<span>{pointPrice}</span>
              </div>
              <div>积分可抵</div>
            </div>
          </div>
          <div className={Styles.finalPrice}>
            <div>
              <span>到手价</span>
              <span>{actualPrice}</span>
            </div>
            <div>去抢购</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShopRecommend;
