import React from 'react';
import * as ShareOfficer from './shareOfficer';
import { NewYear, GiftRecord } from './newYearActivity/index';
import * as regularShare from './regularShare';
import * as activityShare from './activityShare';

import * as staffShare from './staffShare';
import * as SloganVote from './sloganVote';
import rechargeCard from './rechargeCard';
import * as Activity71 from './activity71';
import * as Nucalm from './nucalm';
import DailySign from './dailySignin';
import PointTask from './pointTask/index';
import ExchangeReward from './pointTask/exchangeReward';
import * as MerceDes from './mercedes';
import * as Disney from './disney'
import legal from "./legal/legal";
import Emall from './emall';
import MemberDay from './memberDay';
import * as Nft from './nft';
import * as Waic from './waic';
import * as PetEggs from './petEggs';
import * as Calendar from './calendar';
import * as AllSaintsDay from './allSaintsDay';
import * as Cartoon from './cartoon'
import WorldCupGuess from './worldCupGuess';
import * as Carnival from './carnival';
import Yifuli from './yifuli';
import * as Lottery from './lottery'
import Questionnaire from './questionnaire/childRoute';
import {GoddessHome, ReceiveRecord, GoddessInviteSuccess} from './goddessFestival';
import MothersDay, { ReceivePrizeRecord, LandingPage } from './mothersDay23/index';
import BarrageWall from './mothersDay23/barrage-wall';
import LeagueOfLegends from './leagueOfLegends';
import ChildrensDayHome from './childrensDay/index';
import ChildrensDayShare from './childrensDay/share';
import {ReceiveRecordPage} from './childrensDay/receive-record-page';
import FathersDayHome from './fathersDay/index';
import FathersDayShare from './fathersDay/share';
import AwardRecord from './awardRecord';
import MagpieFestival from './magpieFestival/index';
import QiXiInviteSuccess from './magpieFestival/components/qixiInviteSuccess/index';
import MidAutumn from './midAutumn/index';
import Double11Days, {InviteSuccess as Double11InviteSuccess} from './double11/index';
import InviteSuccess from './midAutumn/components/inviteSuccess/index';
import UnityPointsLOttery from './unityPointsLottery'
import BookBoardroom from './bookUnityBoardroom';
import { ClaimLand } from './claimLand/index';
import CarbonIntegral from './carbonIntegral'
import SpringFestival from './springFestival/index';
import SpringFestivalInviteSuccess from './springFestival/components/inviteSuccess/index';

export const Routes = [
    {
        path: '/front/activities/regularShare/inviter',
        component: regularShare.Inviter,
        title: 'G优超级分享官'
    }, {
        //接受邀请界面
        path: '/front/activities/regularShare/invitee/:code',
        component: regularShare.Invitee,
        title: 'G优超级分享官'
    }, {
        path: '/front/activities/regularShare/:page?/:code?/rule',
        component: regularShare.Rule,
        title: '活动规则'
    }, {
        path: '/front/activities/regularShare/:path/login/:code?',
        component: regularShare.Login,
        title: '登陆'
    }, {
        path: '/front/activities/regularShare/:path?/shareImage/:code',
        component: regularShare.ShareImage,
        title: '活动'
    }, {
        path: '/front/activities/shareOfficer/scoreDraw',
        component: ShareOfficer.ScoreDraw,
        title: '积分抽奖'
    }, {
        path: '/front/activities/shareOfficer/rules',
        component: ShareOfficer.Rules,
        title: '活动规则'
    }, {
        path: '/front/activities/shareOfficer/:path?/shareImage/:code',
        component: ShareOfficer.ShareImage,
        title: '活动'
    },
    {
        path: '/front/activities/regularShare/nft-official',
        component: regularShare.NFTOfficial,
        title: 'NFT超级分享官'
    },
    {
        path: '/front/activities/regularShare/landPage',
        component: regularShare.LandPage,
        title: '下载引导页'
    },
    {
        path: '/front/activities/regularShare/invite-zone',
        component: regularShare.InviteZone,
        title: '邀新专区'
    },
    {
        path: '/front/activities/regularShare/invite-record',
        component: regularShare.InviteRecord,
        title: '邀新记录'
    },
    {
        path: '/front/activities/staffShare',
        component: staffShare.Inviter,
        title: '绿地员工专区'
    }, {
        path: '/front/activities/rechargeCard',
        component: rechargeCard,
        title: '绿地员工专区'
    },
    {
        path: '/front/activities/sloganVote/vote',
        component: SloganVote.Vote,
        title: '投票活动'
    }, {
        path: '/front/activities/sloganVote/rules',
        component: SloganVote.Rules,
        title: '投票参与规则'
    }, {
        path: '/front/activities/activity71/redTheme',
        component: Activity71.RedTheme,
        title: '活动'
    }, {
        path: '/front/activities/activity71/rules',
        component: Activity71.Rules,
        title: '活动规则'
    }, {
        path: '/front/activities/nucalm/home',
        component: Nucalm.Home,
        title: 'NuCalm 体验预约'
    }, {
        path: '/front/activities/nucalm/appointment',
        component: Nucalm.Appointment,
        title: 'NuCalm 体验预约'
    }, {
        path: '/front/activities/nucalm/selectAppointmentDate',
        component: Nucalm.SelectAppointmentDate,
        title: '选择预约时间'
    }, {
        path: '/front/activities/nucalm/appointmentSuccess',
        component: Nucalm.AppointmentSuccess,
        title: '预约成功'
    }, {
        path: '/front/activities/nucalm/recordList',
        component: Nucalm.RecordList,
        title: '预约记录'
    }, {
        path: '/front/activities/mercedes/home/:activityCode',
        component: MerceDes.Home,
        title: '拼人气赢门票'
    }, {
        path: '/front/activities/mercedes/inviter',
        component: MerceDes.Inviter,
        title: '拼人气赢门票'
    }, {
        path: '/front/activities/mercedes/success',
        component: MerceDes.Success,
        title: '拼人气赢门票'
    },
    {
        path: '/front/activities/mercedes/home/:activityCode/rule',
        component: MerceDes.Rule,
        title: '拼人气赢门票'
    },
    {
        path: '/front/activities/mercedes/home/:activityCode/modal',
        component: MerceDes.Modal,
        title: '拼人气赢门票'
    },
    {
        path: '/front/activities/mercedes/home/:activityCode/share',
        component: MerceDes.ShareModal,
        title: '拼人气赢门票分享页'
    },
    {
        path: '/front/activities/disney/home/:activityCode',
        component: Disney.Home,
        title: '迪士尼赢门票'
    },
    {
        path: '/front/activities/disney/inviter',
        component: Disney.Inviter,
        title: '迪士尼赢门票'
    }, {
        path: '/front/activities/disney/success',
        component: Disney.Success,
        title: '迪士尼赢门票'
    },
    {
        path: '/front/activities/disney/home/:activityCode/share',
        component: Disney.ShareModal,
        title: '迪士尼赢门票'
    },
    {
        path: '/front/activities/disney/home/:activityCode/modal',
        component: Disney.Modal,
        title: '迪士尼赢门票'
    },
    {
        path: '/front/activities/disney/home/:activityCode/rule',
        component: Disney.Rule,
        title: '迪士尼赢门票'
    },
    {
        path: '/front/activities/disney/editTeam',
        component: Disney.EditTeam,
        title: '编辑战队成员'
    },
    {
        path: '/front/activities/legal',
        component: legal,
        title: '会员权益'
    },
    {
        path: '/front/activities/nft/login',
        component: Nft.Login,
        title: '登录/注册'
    },
    {
        path: '/front/activities/nft/login/rule',
        component: Nft.Rule,
        title: '登录/注册'
    },
    {
        path: '/front/activities/nft/anniversary',
        component: Nft.Anniversary,
        title: '30周年庆'
    },
    {
        path: '/front/activities/nft/:activityCode/disneyRaffle',
        component: Nft.DisneyRaffle,
        title: '抽奖'
    },
    {
        path: '/front/activities/nft/assemble',
        component: Nft.Assemble,
        title: '数字藏品'
    },
    {
        path: '/front/activities/nft/assembleOld',
        component: Nft.Assemble,
        title: '数字藏品'
    },
    {
        path: '/front/activities/nft/assembleOld2',
        component: Nft.AssembleOld,
        title: '数字藏品'
    },
    {
        path: '/front/activities/nft/assemble/:activityCode/share',
        component: Nft.ShareModal,
        title: '分享'
    },
    {
        path: '/front/activities/nft/editAddress',
        component: Nft.EditAddress,
        title: '编辑收货地址'
    },
    {
        path: '/front/activities/nft/myRewards',
        component: Nft.MyRewards,
        title: '中奖记录'
    },
    {
        path: '/front/activities/nft/drawNumList',
        component: Nft.DrawNumList,
        title: '获取次数记录'
    },
    {
        path: '/front/activities/nft/:activityCode/disneyRaffle/share',
        component: Nft.ShareModal,
        title: '抽奖'
    },
    {
        path: '/front/activities/nft/anniversaryStatic',
        component: Nft.AnniversaryStatic,
        title: '30周年庆'
    },
    {
        path: '/front/activities/nft/hot',
        component: Nft.HotAss,
        title: '稍后重试'
    },
    {
        path: '/front/activities/nft/airdrop',
        component: Nft.AirDrop,
        title: '空投福利'
    },
    {
        path: '/front/activities/waic/guide',
        component: Waic.Guide,
        title: '  '
    },
    {
        path: '/front/activities/waic/gather',
        component: Waic.Gather,
        title: '数字藏品'
    },
    {
        path: '/front/activities/waic/gather_static',
        component: Waic.GatherStatic,
        title: '数字藏品'
    },
    {
        path: '/front/activities/petEggs/petEggHome',
        component: PetEggs.PetEggHome,
        title: '绿地Ape World数字藏品'
    },
    {
        path: '/front/activities/petEggs/petEggHome_static',
        component: PetEggs.PetEggHomeStatic,
        title: '绿地Ape World数字藏品'
    },
    {
        path: '/front/activities/petEggs/views/eggGuide',
        component: PetEggs.EggGuide,
        title: '绿地Ape World数字藏品'
    },
    {
        path: '/front/activities/calendar/calendarHome',
        component: Calendar.CalendarHome,
        title: '绿地Ape World数字藏品'
    },
    {
        path: '/front/activities/allSaintsDay/allSaintsDayHome',
        component: AllSaintsDay.AllSaintsDayHome,
        title: '绿地Ape World数字藏品'
    },
    {
        path: '/front/activities/allSaintsDay/allSaintsDayHome_static',
        component: AllSaintsDay.AllSaintsDayHomeStatic,
        title: '绿地Ape World数字藏品'
    },
    {
        path: '/front/activities/worldCupGuess/guessDetail',
        component: WorldCupGuess.GuessDetail,
        title: ''
    },
    {
        path: '/front/activities/worldCupGuess/myGuess',
        component: WorldCupGuess.MyGuess,
        title: '我的竞猜'
    },
    {
        path: '/front/activities/worldCupGuess/matchDetail',
        component: WorldCupGuess.MatchDetail,
        title: '比赛详情'
    },
    {
        path: '/front/activities/worldCupGuess/guessRankingList',
        component: WorldCupGuess.GuessRankingList,
        title: ''
    },
    {
        path: '/front/activities/worldCupGuess/worldCupIndex',
        component: WorldCupGuess.WorldCupIndex,
        title: ''
    },
    {
        path: '/front/activities/worldCupGuess/worldCupIndex/noticeSetting',
        component: WorldCupGuess.NoticeSetting,
        title: ''
    },
    {
        path: '/front/activities/worldCupGuess/worldCupGuessShare',
        component: WorldCupGuess.WorldCupGuessShare,
        title: ''
    },
    {
        path: '/front/activities/worldCupGuess/worldCupGuessInvite',
        component: WorldCupGuess.WorldCupGuessInvite,
        title: ''
    },
    {
        path: '/front/activities/worldCupGuess/recordDetail',
        component: WorldCupGuess.RecordDetail,
        title: '竞猜详情'
    },
    {
        path: '/front/activities/cartoon/cartoonHome',
        component: Cartoon.CartoonHome,
        title: '共创漫画'
    },
    {
        path: '/front/activities/cartoon/inviteRecord',
        component: Cartoon.InviteRecord,
        title: '邀新记录'
    },
    {
        path: '/front/activities/cartoon/inviteRankList',
        component: Cartoon.InviteRankList,
        title: '共创漫画'
    },
    {
        path: '/front/activities/carnival/home',
        component: Carnival.Home,
        title: '嘉年华'
    },
    {
        path: '/front/activities/carnival/drawTimesRecord',
        component: Carnival.DrawTimesRecord,
        title: '嘉年华'
    },
    {
        path: '/front/activities/carnival/drawRecord',
        component: Carnival.DrawRecord,
        title: '嘉年华'
    },
    {
        path: '/front/activities/carnival/fillAddress',
        component: Carnival.FillAddress,
        title: '填写地址'
    },

    {
        path: '/front/activities/carnival/components/CartoonGuide',
        component: Carnival.CarnivalGuide,
        title: '数字藏品'
    },
    {
        path: '/front/activities/yifuli/home',
        component: Yifuli.Yifuli,
        title: ''
    },
    {
        path: '/front/activities/yifuli/inviteRecord',
        component: Yifuli.InviteRecord,
        title: '邀新记录'
    },
    {
        path: '/front/activities/yifuli/inviteSuccess',
        component: Yifuli.YFLInviteSuccess,
        title: ''
    },
    {
        path: '/front/activities/yifuli/invite/:activityCode?/:inviteCode?',
        component: Yifuli.YFLInvite,
        title: ''
    },
    {
        path: '/front/activities/cartoon/views/cartoonGuide',
        component: Cartoon.CartoonGuide,
        title: '数字藏品'
    },
    {
        path: '/front/activities/pointTask',
        exact: true,
        component: PointTask,
        title: '积分任务'
    },
    {
        path: '/front/activities/pointTask/exchangeReward',
        component: ExchangeReward,
        title: '领取奖品'
    },
    {
        path: '/front/activities/pointTask/exchangeDebris',
        component: React.lazy(()=>import('./pointTask/exchangeDebris')),
        title: '碎片兑换'
    },
    {
        path: '/front/activities/lottery/lotteryHome',
        component: Lottery.LotteryHome,
        title: '积分抽奖'
    },
    {
        path: '/front/activities/lottery/rule',
        component: Lottery.Rule,
        title: '积分抽奖规则'
    },
    {
        path: '/front/activities/lottery/views/raffleRecord',
        component: Lottery.RaffleRecord,
        title: '抽奖记录'
    },
    {
        path: '/front/activities/lottery/orderDetail',
        component: Lottery.OrderDetail,
        title: '订单详情'
    },
    {
        //废弃
        path: '/front/activities/lottery/views/addressMgr',
        component: Lottery.AddressMgr,
        title: '地址管理'
    },
    {
        path: '/front/activities/newYear',
        component: NewYear,
        exact: true,
        title: '双旦活动'
    },
    {
        path: '/front/activities/newYear/giftRecord',
        component: GiftRecord,
        exact: true,
        title: '中奖记录'
    },
    {
        path: '/front/activities/goddessHome',
        component: GoddessHome,
        title: '女神节'
    },
    {
        path: '/front/activities/receiveRecord',
        component: ReceiveRecord,
        title: '领取记录'
    },
    {
        path: '/front/activities/goddessInviteSuccess',
        component: GoddessInviteSuccess,
        title: ''
    },
    {
        path: '/front/activities/beautyDay',
        component: React.lazy(()=>import('./beautyDay/index')),
        title: '美妆节'
    },
    {
        path: '/front/activities/waic/guideTest',
        component: Waic.GuideTest,
        title: '  '
    },
    {
        path: '/front/activities/mothersDay',
        component: MothersDay,
        exact: true,
        title: '感恩母亲节'
    },
    {
        path: '/front/activities/barrage-wall',
        component: BarrageWall,
        title: '母亲节祝福墙'
    },
    {
        path: '/front/activities/mothersDay/receive-prize-record',
        component: ReceivePrizeRecord,
        exact: true,
        title: '母亲节领奖记录'
    },
    {
        //母亲节分享页
        path: '/front/activities/mothersDay/landingPage',
        component: LandingPage,
        title: '感恩母亲节'
    },
    {
      path: '/front/activities/leagueOfLegends',
      component: LeagueOfLegends,
      title: '  '
    },
    {
        // 儿童节 活动页
        path: '/front/activities/childrensDay',
        component: ChildrensDayHome,
        exact: true,
        title: '儿童节'
    },
    {
        // 儿童节 分享页
        path: '/front/activities/childrensDay/share',
        component: ChildrensDayShare,
        title: '欢购儿童节'
    },
    {
        path: '/front/activities/childrensDay/receive-record-page',
        component: ReceiveRecordPage,
        exact: true,
        title: '儿童节领奖记录'
    },
    {
        // 父亲节 活动页
        path: '/front/activities/fathersDay',
        component: FathersDayHome,
        exact: true,
        title: '父亲节'
    },
    {
        // 父亲节 分享页
        path: '/front/activities/fathersDay/share',
        component: FathersDayShare,
        exact: true,
        title: '父亲节'
    },
    {
        // 活动 领取记录: /front/activities/awardRecord?activityCode=child2023&apiCode=123456
        path: '/front/activities/awardRecord',
        component: AwardRecord,
        exact: true,
        title: '领取记录'
    },
   {
        // 七夕节
        path: '/front/activities/magpieFestival',
        component: MagpieFestival,
        exact: true,
        title: '七夕节'
   },
    {
        // 七夕节下载
        path: '/front/activities/qixiInviteSuccess',
        component: QiXiInviteSuccess,
        exact: true,
        title: ''
    },
    {
      path: '/front/activities/midAutumn',
      component: MidAutumn,
      exact: true,
      title: '中秋节'
    },
    {
        path: '/front/activities/inviteSuccess',
        component: InviteSuccess,
        exact: true,
        title: ''
    },
    {
        // 双十一
        path: '/front/activities/double11',
        component: Double11Days,
        exact: true,
        title: '双十一'
    },
    {
      path: '/front/activities/double11/inviteSuccess',
      component: Double11InviteSuccess,
      exact: true,
      title: '双十一'
    },
    {
      path: '/front/activities/unityPointsLottery',
      component: UnityPointsLOttery.UnityPointsLottery,
      exact: true,
      title: '积分抽奖'
    },
    {
      path: '/front/activities/unityPointsLottery/rules',
      component: UnityPointsLOttery.ActivityRules,
      exact: true,
      title: '活动规则'
    },
    {
      path: '/front/activities/unityPointsLottery/address',
      component: UnityPointsLOttery.FillInAddress,
      exact: true,
      title: ''
    },
    {
      path: '/front/activities/unityPointsLottery/performerInfo',
      component: UnityPointsLOttery.PerformerInfo,
      exact: true,
      title: ''
    },
    {
      path: '/front/activities/unityPointsLottery/selectAddress',
      component: UnityPointsLOttery.SelectAddress,
      exact: true,
      title: ''
    },
    {
      path: '/front/activities/unityPointsLottery/realNamePro',
      component: UnityPointsLOttery.RealNamePro,
      exact: true,
      title: ''
    },
    {
      path: '/front/activities/unityPointsLottery/records',
      component: UnityPointsLOttery.LotteryRecords,
      exact: true,
      title: ''
    },
    {
      path: '/front/activities/unityPointsLottery/share',
      component: UnityPointsLOttery.Share,
      exact: true,
      title: ''
    },
    {
      path: '/front/activities/boardroom',
      component: BookBoardroom,
      exact: true,
      title: ''
    },
    {
        path: '/front/activities/claimLand',
        component: ClaimLand,
        exact: true,
        title: '扫码领地'
    },
    {
        path: '/front/activities/carbonIntegralHome',
        component: CarbonIntegral.CarbonIntegralHome,
        exact: true,
        title: ''
    },{
        path: '/front/activities/carbonIntegralHome/exchangeRecord',
        component: CarbonIntegral.ExchangeRecord,
        exact: true,
        title: '兑换记录'
    },
    {
        path: '/front/activities/enviroPro',
        component: CarbonIntegral.EnviroPro,
        exact: true,
        title: '环保商品'
    }, {
        path: '/front/activities/carbonIntegralHome/inviteSuccess',
        component: CarbonIntegral.InviteSuccess,
        exact: true,
        title: '绿地G优'
    }, {
        path: '/front/activities/activityShare/invite-zone',
        component: activityShare.InviteZone,
        title: '醉美茅台'
    }, {
        path: '/front/activities/activityShare/invite-record',
        component: activityShare.InviteRecord,
        title: '醉美茅台-邀请记录'
    },
    {
        path: '/front/activities/activityShare/ticket-record',
        component: activityShare.TicketRecord,
        title: '醉美茅台-我的抽奖券'
    },
    {
        path: '/front/activities/springFestival',
        component: SpringFestival,
        exact: true,
        title: '春节'
      },
      {
        path: '/front/activities/springFestival/inviteSuccess',
        component: SpringFestivalInviteSuccess,
        exact: true,
        title: ''
    },

].concat(DailySign, Emall, MemberDay, Questionnaire);

